<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="col-md-4 col-xl-2"
          placeholder="Cerca..."
      ></b-form-input>
      <div class="ml-auto">
        <b-button class="mr-3 mb-3 ml-auto" variant="success" v-b-modal.editModal
                  @click="newItem">
          + Crea Nuovo
        </b-button>
        <b-button class="mb-3 ml-auto" variant="warning"
                  @click="exportCsv">
          Esporta CSV
        </b-button>
      </div>
    </div>
    <b-table
        hover
        bordered
        :filter="filter"
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
    >
      <!-- A custom formatted column -->
      <template #cell(family_parent_id)="data" width="20" class="text-center">
        <template v-if="data.item.caregiver_id > 0">
          {{data.item.caregiver.name}}
          <a class="mx-1 cursor-pointer" @click="editItem(data.item.caregiver, 'editCaregiver', data.item.caregiver.tags, data.item.id)"
          ><feather type="edit-3" class="feather-sm"></feather
          ></a>
        </template>
        <template v-else-if="data.item.clinic_id > 0 && data.item.clinic !== undefined">
          {{ '(Clinica) '+data.item.clinic.company_name }}
          <a class="mx-1 cursor-pointer" @click="editItem(data.item.clinic, 'editClinic', data.item.clinic.tags, data.item.id)"
          ><feather type="edit-3" class="feather-sm"></feather
          ></a>
        </template>
        <template v-else>--</template>
      </template>
      <template #cell(Azioni)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="editItem(data.item, 'editPatient', data.item.tags, data.item.id)"
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
        <a
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item)"
        ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <NewPatient ref="newPatient" />
    <EditCaregiver ref="editCaregiver" />
    <EditClinic ref="editClinic" />
    <EditPatient ref="editPatient" />
  </b-card>
</template>

<script>
export default {
  name: "PatientList",
  components: {
    NewPatient: () => import("@/views/patient/components/NewPatient"),
    EditCaregiver: () => import("@/views/patient/components/EditCaregiver"),
    EditClinic: () => import("@/views/patient/components/EditClinic"),
    EditPatient: () => import("@/views/patient/components/EditPatient"),
  },
  data: () => ({
    count_checkbox_caregiver: 0,
    selected_tab_wizard: 0,
    checkbox_caregiver: false,
    loadingWizard: false,
    errorMsg: null,
    sortBy: "id",
    fields: [],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      name: '',
      email: '',
      is_caregiver: true,
      dob: '',
    },
    defaultItem: {
      id: "",
      name: '',
      email: '',
      is_caregiver: true,
      dob: '',
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      if (this.user.user_rule === 'vector') {
        this.fields = [
          { key: "id", sortable: true },
          { key: "family_parent_id", sortable: true, label: 'Caregiver' },
          { key: "name", label: "Nome" },
          { key: "email", label: "Email" },
          "Azioni",
        ]
        const res = await this.axios.get('patients/vector/'+this.user.user.id)
        await this.$store.dispatch("setDataTable", res.data)
      } else {
        this.fields = [
          { key: "id", sortable: true },
          { key: "vector.authinfo.name", sortable: true, label: 'Vettore' },
          { key: "family_parent_id", sortable: true, label: 'Caregiver' },
          { key: "name", label: "Nome" },
          { key: "email", label: "Email" },
          "Azioni",
        ]
        const res = await this.axios.get('patients/')
        await this.$store.dispatch("setDataTable", res.data)
      }
    },
    editItem(item, modal, tags, patient_id) {
      this.$refs[modal].editedItem = item;
      this.$refs[modal].editedItem.patient_id = patient_id;
      this.$refs[modal].$refs["edit-modal"].show();

      const that = this
      setTimeout( function () {
        if (tags !== null) that.$refs[modal].$refs.selectTag.value = tags.split(',')
        if (that.$refs[modal].$refs.gmaps_address !== undefined) {
          if (item.residence_address !== undefined) {
            that.$refs[modal].$refs.gmaps_address.$refs.gmaps_ref.$refs.input.value = item.residence_address
          } else {
            that.$refs[modal].$refs.gmaps_address.$refs.gmaps_ref.$refs.input.value = item.address
          }
        }
      }, 300)
    },
    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async exportCsv() {
      if (this.user.user_rule === 'vector') {
        const res = await this.axios.get('patients/vector/'+this.user.user.id+'/export/csv')
        window.open(res.data.replace('http://localhost/', 'https://admin.niino.it:446/'), '_blank');
      } else {
        const res = await this.axios.get('patients/export/csv')
        window.open(res.data.replace('http://localhost/', 'https://admin.niino.it:446/'), '_blank');
      }
    },
    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Paziente',
        text: 'Sei sicuro di voler eliminare '+item.name+'?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('patient/'+item.id)
          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Paziente',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs.newPatient.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    }
  },
  computed: {
    user(){
      return this.$store.state.authentication;
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    }
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>